import React, {useContext} from "react";
import {
  Container,
  Nav,
  Navbar,
} from "react-bootstrap";
import * as ROUTES from "../../constants/routes";
import {AuthContext} from "../auth/AuthContext";
import {LogoutNavButton} from "../auth/LogoutNavButton";
import {faGear} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {ProtectedComponent} from "../auth/ProtectedComponent";

export const Navigation = () => {
  const {jwt} = useContext(AuthContext);
  return (
    <Navbar bg="light" expand="lg">
      <Container>
        {jwt &&
          <Navbar.Brand href={ROUTES.PROOF_LIST}>
            <img
              alt=""
              src="https://mydesign.report/media/img/mydesign.report-icon.svg"
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{' '}
            Druckfreigabe Admin
          </Navbar.Brand>
        }
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          {jwt &&
            <Nav>
              <Nav.Item>
                <Nav.Link href={ROUTES.PROOF_LIST}>Übersicht</Nav.Link>
              </Nav.Item>
            </Nav>
          }
          <Nav className="ms-auto w-100 justify-content-end">
            {jwt
              ?
              <>
                <ProtectedComponent>
                  <Nav.Item>
                    <Nav.Link href={ROUTES.SETTINGS_OVERVIEW}><FontAwesomeIcon icon={faGear}/></Nav.Link>
                  </Nav.Item>
                </ProtectedComponent>
                <LogoutNavButton/>
              </>
              :
              <Nav.Item>
                <Nav.Link href={ROUTES.LOGIN}>Login</Nav.Link>
              </Nav.Item>
            }
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};