import React from "react";
import {gql, useMutation, useQuery} from "@apollo/client";
import {Button, Col, Container, FormControl, FormGroup, FormLabel, FormSelect, Nav, Row} from "react-bootstrap";
import {BrandListQuery, ProofDetailQuery} from "../../generated/graphql";
import {useParams} from "react-router-dom";
import {Form, Formik} from "formik";
import {toast} from "react-toastify";
import {QRY_BRAND_LIST} from "../../components/brand/Listing";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare, faFloppyDisk} from "@fortawesome/free-solid-svg-icons";
import {ProofRemove} from "../../components/proof/Remove";
import {Permissions} from "../../components/auth/AuthContext";
import {ProtectedComponent} from "../../components/auth/ProtectedComponent";

const QRY_PROOF_DETAIL = gql`
  query ProofDetail($uuid: String!) {
    proofByUuid(uuid: $uuid) {
      id
      name
      templatePdf
      templateImage
      productReference
      productTitle
      referenceInternal
      brand {
        id
        name
      }
      printColorModel
      printTechnology
      surfaceType
      version
      comment
      status
      reviewedAt
      createdAt
      updatedAt
    }
  }
`;

const MUT_PROOF_UPDATE = gql`
  mutation updateProof($updateProofInput: UpdateProofInput!) {
    updateProof(updateProofInput: $updateProofInput) {
      id
      name
      templatePdf
      templateImage
      productReference
      productTitle
      referenceInternal
      brand {
        id
      }
      printColorModel
      printTechnology
      surfaceType
      version
      comment
      status
      reviewedAt
      createdAt
      updatedAt
    }
  }
`;

//const validationSchema = {}

export const ProofDetail = () => {
  const params = useParams();
  const { data } = useQuery<ProofDetailQuery>(
    QRY_PROOF_DETAIL,
    { variables: { uuid: params.uuid } }
  );
  const { data: brandsData } = useQuery<BrandListQuery>(QRY_BRAND_LIST);

  const [updateProof] = useMutation(MUT_PROOF_UPDATE, {
    onCompleted: () => {
      toast.success("Druckfreigabe aktualisiert.");
    },
    onError: () => {
      toast.error("Fehler beim Aktualisieren der Druckfreigabe!");
    },
  });

  return (
    <Container>
      {data && data.proofByUuid && (
        <Formik
          enableReinitialize={true}
          initialValues={data}
          onSubmit={(data, { setSubmitting }) =>
            updateProof({
              variables: { updateProofInput: data.proofByUuid },
            })
          }
        >
          {(form) => (
            <Form>
              <Nav className="mt-2">
                <ProtectedComponent permission={Permissions.proofUpdate}>
                  <Button className="me-2" variant="success" type="submit">
                    <FontAwesomeIcon icon={faFloppyDisk} /> Speichern
                  </Button>
                </ProtectedComponent>
                <Button className="me-2" variant="outline-secondary" href={`${process.env.REACT_APP_FRONTEND_SERVER}${process.env.REACT_APP_FRONTEND_PROOF_DIR}${params.uuid}`} target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} /> Öffnen
                </Button>
                <ProofRemove id={data.proofByUuid.id} />
              </Nav>

              <div className="my-4">
                <h2>Auftragsdaten</h2>
                <Row>
                  <Col md>
                    <FormGroup className="mb-3" controlId="proofByUuid.referenceInternal">
                      <FormLabel>Referenz*</FormLabel>
                      <FormControl
                        type="text"
                        name="proofByUuid.referenceInternal"
                        required
                        defaultValue={form.values.proofByUuid.referenceInternal}
                        onChange={form.handleChange} />
                    </FormGroup>
                  </Col>
                  <Col md>
                    <FormGroup className="mb-3" controlId="proofByUuid.productTitle">
                      <FormLabel>Produkt Name*</FormLabel>
                      <FormControl
                        type="text"
                        name="proofByUuid.productTitle"
                        required
                        defaultValue={form.values.proofByUuid.productTitle}
                        onChange={form.handleChange} />
                    </FormGroup>
                  </Col>
                  <Col md>
                    <FormGroup className="mb-3" controlId="proofByUuid.productReference">
                      <FormLabel>Produkt Referenz*</FormLabel>
                      <FormSelect
                        name="proofByUuid.productReference"
                        defaultValue={form.values.proofByUuid.productReference || "331182-dw-8oz-rect"}
                        onChange={form.handleChange}
                      >
                        <option value="none">keine</option>
                        <option disabled>-----EWB-----</option>
                        <option value="331182-dw-8oz-rect">331182-dw-8oz-rect</option>
                        <option value="331182-dw-8oz-warp">331182-dw-8oz-warp</option>
                        <option value="331182-dw-12oz-rect">331182-dw-12oz-rect</option>
                        <option value="331182-dw-12oz-warp">331182-dw-12oz-warp</option>
                        <option value="331182-dw-16oz-rect">331182-dw-16oz-rect</option>
                        <option value="331182-dw-16oz-warp">331182-dw-16oz-warp</option>
                        <option value="331182-sw-4oz-rect">331182-sw-4oz-rect</option>
                        <option value="331182-sw-4oz-warp">331182-sw-4oz-warp</option>
                        <option value="331182-sw-8oz-rect">331182-sw-8oz-rect</option>
                        <option value="331182-sw-8oz-warp">331182-sw-8oz-warp</option>
                        <option value="331182-sw-12oz-rect">331182-sw-12oz-rect</option>
                        <option value="331182-sw-12oz-warp">331182-sw-12oz-warp</option>
                        <option value="331182-sw-16oz-rect">331182-sw-16oz-rect</option>
                        <option value="331182-sw-16oz-warp">331182-sw-16oz-warp</option>
                        <option value="331182-sw-ice-4oz-rect">331182-sw-ice-4oz-rect</option>
                        <option value="331182-sw-ice-4oz-warp">331182-sw-ice-4oz-warp</option>
                        <option value="331182-sw-ice-6oz-rect">331182-sw-ice-6oz-rect</option>
                        <option value="331182-sw-ice-6oz-warp">331182-sw-ice-6oz-warp</option>
                        <option value="331182-sw-ice-8oz-rect">331182-sw-ice-8oz-rect</option>
                        <option value="331182-sw-ice-8oz-warp">331182-sw-ice-8oz-warp</option>
                        <option value="331215-dw-225ml-rect">331215-dw-225ml-rect</option>
                        <option value="331215-sw-100ml-rect">331215-sw-100ml-rect</option>
                        <option value="331215-sw-150ml-rect">331215-sw-150ml-rect</option>
                        <option value="331215-sw-180ml-rect">331215-sw-180ml-rect</option>
                        <option value="331215-sw-225ml-rect">331215-sw-225ml-rect</option>
                        <option value="331215-sw-300ml-rect">331215-sw-300ml-rect</option>
                        <option disabled>-----MWB-----</option>
                        <option value="331179-pru-200ml-rect">331179-pru-200ml-rect</option>
                        <option value="331213-pru-250ml-rect">331213-pru-250ml-rect</option>
                        <option value="331213-pru-300ml-rect">331213-pru-300ml-rect</option>
                        <option value="331213-pru-400ml-rect">331213-pru-400ml-rect</option>
                        <option value="331213-pru-500ml-rect">331213-pru-500ml-rect</option>
                        <option value="331213-pru-500ml-iml-elm">331213-pru-500ml-iml-elm</option>
                        <option value="331243-pru-250ml-rect">331243-pru-250ml-rect</option>
                        <option value="331243-pru-300ml-rect">331243-pru-300ml-rect</option>
                        <option value="331243-pru-400ml-rect">331243-pru-400ml-rect</option>
                        <option value="331243-pru-500ml-rect">331243-pru-500ml-rect</option>
                        <option disabled>-----test-----</option>
                        <option value="999999-sw-8oz-rect">999999-sw-8oz-rect</option>
                      </FormSelect>
                    </FormGroup>
                  </Col>
                  <Col md>
                    <FormGroup className="mb-3" controlId="proofByUuid.status">
                      <FormLabel>Status*</FormLabel>
                      <FormSelect
                        name="proofByUuid.status"
                        defaultValue={form.values.proofByUuid?.status || "offen"}
                        onChange={form.handleChange}
                      >
                        <option value="offen">offen</option>
                        <option value="angenommen">angenommen</option>
                        <option value="abgelehnt">abgelehnt</option>
                        <option value="storniert">storniert</option>
                      </FormSelect>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className="mb-3" controlId="proofByUuid.brand">
                      <FormLabel>Marke</FormLabel>
                      <FormSelect
                        name="proofByUuid.brand"
                        defaultValue={form.values.proofByUuid.brand?.id}
                        onChange={(option) => form.setFieldValue('proofByUuid.brand', brandsData?.brands.find(brand => brand.id === parseInt(option.target.value)))}
                      >
                        {brandsData?.brands.map((brand, key) => (
                          <option key={key} value={brand.id}>{brand.name}</option>
                        ))}
                      </FormSelect>
                    </FormGroup>
                  </Col>
                </Row>
              </div>

              <Row>
                <Col md>
                  <FormGroup className="mb-3" controlId="proofByUuid.name">
                    <FormLabel>Name</FormLabel>
                    <FormControl
                      type="text"
                      name="proofByUuid.name"
                      disabled
                      defaultValue={form.values.proofByUuid?.name || ""}
                      onChange={form.handleChange} />
                  </FormGroup>
                </Col>
                <Col md>
                  <FormGroup className="mb-3" controlId="proofByUuid.reviewedAt">
                    <FormLabel>Datum</FormLabel>
                    <FormControl
                      type="text"
                      name="proofByUuid.reviewedAt"
                      disabled
                      defaultValue={form.values.proofByUuid?.reviewedAt && new Date(form.values.proofByUuid?.reviewedAt).toLocaleString('de-DE', { timeZone: 'Europe/Vienna' })}
                      onChange={form.handleChange} />
                  </FormGroup>
                </Col>
              </Row>

              <FormGroup className="mb-3" controlId="proofByUuid.comment">
                <FormLabel>Anmerkung</FormLabel>
                <FormControl
                  as="textarea"
                  name="proofByUuid.comment"
                  disabled
                  defaultValue={form.values.proofByUuid?.comment || ""}
                  onChange={form.handleChange}
                  rows={3} />
              </FormGroup>

              <div className="mt-5">
                <h2>Druckdaten</h2>
                <Row>
                  <Col sm>
                    <FormGroup className="mb-3" controlId="proofByUuid.printTechnology">
                      <FormLabel>Produktionsart</FormLabel>
                      <FormControl
                        type="text"
                        name="proofByUuid.printTechnology"
                        defaultValue={form.values.proofByUuid?.printTechnology || ""}
                        onChange={form.handleChange} />
                    </FormGroup>
                  </Col>
                  <Col sm>
                    <FormGroup className="mb-3" controlId="proofByUuid.printColorModel">
                      <FormLabel>Farbmodus</FormLabel>
                      <FormControl
                        type="text"
                        name="proofByUuid.printColorModel"
                        defaultValue={form.values.proofByUuid?.printColorModel || ""}
                        onChange={form.handleChange} />
                    </FormGroup>
                  </Col>
                  <Col sm>
                    <FormGroup className="mb-3" controlId="proofByUuid.version">
                      <FormLabel>Version</FormLabel>
                      <FormControl
                        type="text"
                        name="proofByUuid.version"
                        defaultValue={form.values.proofByUuid?.version || ""}
                        onChange={form.handleChange} />
                    </FormGroup>
                  </Col>
                  <Col sm>
                    <FormGroup className="mb-3" controlId="proofByUuid.surfaceType">
                      <FormLabel>Oberfläche Typ*</FormLabel>
                      <FormSelect
                        name="proofByUuid.surfaceType"
                        defaultValue={form.values.proofByUuid?.surfaceType || "matt"}
                        required
                        onChange={form.handleChange}
                      >
                        <option value="matt">matt</option>
                        <option value="glossy">glossy</option>
                      </FormSelect>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md>
                    <FormGroup className="mb-3" controlId="proofByUuid.templatePdf">
                      <FormLabel>Druckdatei PDF</FormLabel>
                      <FormControl
                        type="text"
                        name="proofByUuid.templatePdf"
                        defaultValue={form.values.proofByUuid?.templatePdf || ""}
                        onChange={form.handleChange} />
                    </FormGroup>
                    <Button size="sm" variant="outline-secondary"
                      onClick={() => {
                        navigator.clipboard.writeText(`${params.uuid}.pdf`);
                        toast.info("PDF Dateiname in Zwischenablage kopiert.");
                      }}
                    >
                      generieren
                    </Button>

                  </Col>
                  <Col md>
                    <FormGroup className="mb-3" controlId="proofByUuid.templateImage">
                      <FormLabel>Druckdatei Bild</FormLabel>
                      <FormControl
                        type="text"
                        name="proofByUuid.templateImage"
                        defaultValue={form.values.proofByUuid?.templateImage || ""}
                        onChange={form.handleChange} />
                    </FormGroup>
                    <Button size="sm" variant="outline-secondary"
                      onClick={() => {
                        navigator.clipboard.writeText(`${params.uuid}.jpg`);
                        toast.info("Bild Dateiname in Zwischenablage kopiert.");
                      }}
                    >
                      generieren
                    </Button>
                  </Col>
                </Row>
              </div>

              <div className="my-5">
                <h2>Log</h2>
                <Row>
                  <Col md>
                    <FormGroup className="mb-3">
                      <FormLabel>Erstellung</FormLabel>
                      <FormControl
                        type="text"
                        disabled
                        defaultValue={new Date(data.proofByUuid?.createdAt).toLocaleString('de-DE', { timeZone: 'Europe/Vienna' })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md>
                    <FormGroup className="mb-3">
                      <FormLabel>Review Kunde</FormLabel>
                      <FormControl
                        type="text"
                        disabled
                        defaultValue={data.proofByUuid?.reviewedAt && new Date(data.proofByUuid?.reviewedAt).toLocaleString('de-DE', { timeZone: 'Europe/Vienna' })}
                      />
                    </FormGroup>
                  </Col>
                  <Col md>
                    <FormGroup className="mb-3">
                      <FormLabel>Änderung</FormLabel>
                      <FormControl
                        type="text"
                        disabled
                        defaultValue={new Date(data.proofByUuid?.updatedAt).toLocaleString('de-DE', { timeZone: 'Europe/Vienna' })}
                      />
                    </FormGroup>
                  </Col>
                </Row>

              </div>
            </Form>
          )}
        </Formik>
      )}
    </Container>
  )
}