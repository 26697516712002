import React, {useState} from "react";
import {gql, useQuery} from "@apollo/client";
import {
  Accordion,
  Button,
  Col,
  Container,
  FormControl,
  FormGroup,
  FormLabel,
  Nav,
  Row,
  Table
} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowUpRightFromSquare, faPen, faPlus} from "@fortawesome/free-solid-svg-icons";
import {ProofListQuery} from "../../generated/graphql";
import {ProofCreate} from "../../components/proof/Create";

const QRY_PROOF_LIST = gql`
  query ProofList($listProofInput: ListProofInput!) {
    listProofs(listProofInput: $listProofInput) {
      id
      uuid
      productReference
      productTitle
      referenceInternal
      templateImage
      brand {
        id
        name
      }
      version
      status
      reviewedAt
      createdAt
      updatedAt
    }
    brands {
      id
      name
    }
  }
`;

export const ProofListing = () => {
  const {data, refetch} = useQuery<ProofListQuery>(QRY_PROOF_LIST,
    { variables: { listProofInput: {} } }
  );

  const [orderByUpdatedAt, setOrderByUpdatedAt] = useState(false);
  const [filterByReferenceInternal, setFilterByReferenceInternal] = useState("");

  const [filterByBrand, setFilterByBrand] = useState<number[]>([]);
  const initialBrands = () => {
    return data?.brands ? data?.brands.map(brand => brand.id) : [];
  };
  const getFilterByBrand = () => {
    if (filterByBrand.length === 0) {
      setFilterByBrand(initialBrands());
      return initialBrands();
    } else {
      return filterByBrand;
    }
  }
  const resetFilterByBrand = () => {
    if (filterByBrand.length === initialBrands().length) {
      setFilterByBrand([]);
    } else {
      setFilterByBrand(initialBrands());
    }
  }

  const initialStatus = ["offen", "angenommen", "abgelehnt", "storniert"];
  const [filterByStatus, setFilterByStatus] = useState<string[]>(initialStatus);
  const getFilterByStatus = () => {
    if (filterByStatus.length === 0) {
      setFilterByStatus(initialStatus);
      return initialStatus;
    } else {
      return filterByStatus;
    }
  }
  const resetFilterByStatus = () => {
    if (filterByStatus.length === initialStatus.length) {
      setFilterByStatus([]);
    } else {
      setFilterByStatus(initialStatus);
    }
  }

  return (
    <Container>
      <Nav className="my-2">
        <ProofCreate refetch={refetch} />
      </Nav>

      <Accordion className="my-3">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Filter</Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col md={4}>
                <FormGroup className="mb-3" controlId="referenceInternal">
                  <FormLabel title="Reset" role="button" onClick={() => {setFilterByReferenceInternal("");}}>
                    Referenz
                  </FormLabel>
                  <FormControl
                    type="text"
                    name="referenceInternal"
                    value={filterByReferenceInternal}
                    onChange={e => setFilterByReferenceInternal(e.target.value)} />
                </FormGroup>
              </Col>


              <Col md={4}>
                <span title="Reset" role="button" onClick={() => {resetFilterByBrand();}}>
                  Marke
                </span>
                <Row>
                  {data && data.brands && data.brands.map((brand, key) => {
                    const active = filterByBrand.find(x => x === brand.id)
                    return (
                      <Col key={key}>
                        <Button
                          variant={active ? 'success' : 'light'}
                          className="my-2"
                          onClick={() => {
                            active ?
                              setFilterByBrand(filterByBrand.filter(item => item !== brand.id))
                            :
                              setFilterByBrand(filterByBrand => [...filterByBrand, brand.id])
                          }}>
                          {brand.name}
                        </Button>
                      </Col>
                    )
                  })}
                </Row>
              </Col>

              <Col md={4}>
            <span title="Reset" role="button" onClick={() => {resetFilterByStatus();}}>
              Status
            </span>
              <Row>
                {initialStatus.map((status, key) => {
                  const active = filterByStatus.find(x => x === status)
                  return (
                    <Col key={key}>
                      <Button
                        variant={active ? 'success' : 'light'}
                        className="my-2"
                        onClick={() => {
                          active ?
                            setFilterByStatus(filterByStatus.filter(item => item !== status))
                          :
                            setFilterByStatus(filterByStatus => [...filterByStatus, status])
                        }}>
                        {status}
                      </Button>
                    </Col>
                  )
                })}
              </Row>
              </Col>
            </Row>

            <Row>
              <Button onClick={() => {
                refetch({
                  listProofInput: {
                    orderByUpdatedAt: orderByUpdatedAt,
                    filterByReferenceInternal: filterByReferenceInternal,
                    filterByBrand: getFilterByBrand(),
                    filterByStatus: getFilterByStatus()
                  },
                });
              }}>
                Filter
              </Button>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Table striped bordered hover responsive="lg" className="align-middle">
        <thead>
        <tr>
          <th></th>
          <th>Druck</th>
          <th>Referenz</th>
          <th>Produkt</th>
          <th>Version</th>
          <th>Marke</th>
          <th>Status</th>
          <th role="button" onClick={() => {
            refetch({
              listProofInput: {
                orderByUpdatedAt: !orderByUpdatedAt,
                filterByReferenceInternal: filterByReferenceInternal,
                filterByBrand: getFilterByBrand(),
                filterByStatus: getFilterByStatus()
              },
            });
            setOrderByUpdatedAt(!orderByUpdatedAt);
          }}>
            Datum {orderByUpdatedAt ? <FontAwesomeIcon icon={faPen} /> : <FontAwesomeIcon icon={faPlus} />}
          </th>
        </tr>
        </thead>
        <tbody>
        {data &&
          data.listProofs &&
          data.listProofs.map((proof, key) => (
            <tr key={key}>
              <td>
                <Button className="me-2" variant="warning" href={"/proof/" + proof.uuid}>
                  <FontAwesomeIcon icon={faPen} />
                </Button>
                <Button className="me-2" variant="outline-secondary" href={`${process.env.REACT_APP_FRONTEND_SERVER}${process.env.REACT_APP_FRONTEND_PROOF_DIR}${proof.uuid}`} target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                </Button>
              </td>
              <td>
                {proof.templateImage &&
                  <a href={"/proof/" + proof.uuid}>
                    <img alt={proof.referenceInternal} style={{maxHeight:39,maxWidth:85}} height="39" width="85" className="border" loading="lazy" src={`${process.env.REACT_APP_UPLOADS_SERVER}${process.env.REACT_APP_UPLOADS_DIR}${proof.templateImage}`} />
                  </a>
                }
              </td>
              <td>{proof.referenceInternal}</td>
              <td>{proof.productReference}</td>
              <td>{proof.version}</td>
              <td>{proof.brand?.name}</td>
              <td
                style={proof.reviewedAt && {cursor:"help"}}
                title={proof.reviewedAt && new Date(proof.reviewedAt).toLocaleString('de-DE', { timeZone: 'Europe/Vienna' })}>
                  {proof.status}
              </td>
              <td>
                <span className="small" style={{cursor:"help"}} title={new Date(proof.createdAt).toLocaleString('de-DE', { timeZone: 'Europe/Vienna' })}>
                  <FontAwesomeIcon icon={faPlus} /> {new Date(proof.createdAt).toLocaleString('de-DE', { timeZone: 'Europe/Vienna', dateStyle: 'medium' })}
                </span><br />
                <span className="small" style={{cursor:"help"}} title={new Date(proof.updatedAt).toLocaleString('de-DE', { timeZone: 'Europe/Vienna' })}>
                  <FontAwesomeIcon icon={faPen} /> {new Date(proof.updatedAt).toLocaleString('de-DE', { timeZone: 'Europe/Vienna', dateStyle: 'medium' })}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  )
}
