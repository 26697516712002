import React, {useState} from "react";
import {
  Button,
  Col,
  FormControl,
  FormGroup,
  FormLabel,
  FormSelect,
  Modal,
  ModalHeader,
  ModalTitle,
  Row
} from "react-bootstrap";
import {Form, Formik} from "formik";
import {gql, useMutation, useQuery} from "@apollo/client";
import * as Yup from "yup";
import {toast} from "react-toastify";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFloppyDisk, faPlus} from "@fortawesome/free-solid-svg-icons";
import {BrandListQuery} from "../../generated/graphql";
import {QRY_BRAND_LIST} from "../brand/Listing";
import {ProtectedComponent} from "../auth/ProtectedComponent";
import {Permissions} from "../auth/AuthContext";

const MUT_PROOF_CREATE = gql`
  mutation createProof($createProofInput: CreateProofInput!) {
    createProof(createProofInput: $createProofInput) {
      uuid
    }
  }
`;

const validationSchema = Yup.object().shape({
  templateImage: Yup.string().notRequired(),
  templatePdf: Yup.string().notRequired(),
  productReference: Yup.string().required(),
  productTitle: Yup.string().required(),
  referenceInternal: Yup.string().required(),
  brand: Yup.object().notRequired(),
  printColorModel: Yup.string().notRequired(),
  printTechnology: Yup.string().notRequired(),
  surfaceType: Yup.string().notRequired(),
  version: Yup.string().notRequired(),
  status: Yup.string().notRequired(),
});

export const ProofCreate = (listing: {
  refetch: any;
}) => {
  const {data} = useQuery<BrandListQuery>(QRY_BRAND_LIST);
  const [createProof] = useMutation(MUT_PROOF_CREATE, {
    onCompleted: () => {
      toast.success("Druckfreigabe erstellt.");
      handleClose();
      listing.refetch();
    },
    onError: () => {
      toast.error("Fehler beim Erstellen der Druckfreigabe!");
    },
  });

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <ProtectedComponent permission={Permissions.proofCreate}>
        <Button variant="success" onClick={handleShow}>
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </ProtectedComponent>

      <Modal show={show} onHide={handleClose} size="lg">
        <ModalHeader closeButton>
          <ModalTitle>Druckfreigabe erstellen</ModalTitle>
        </ModalHeader>
        <Modal.Body>

          <Formik
            initialValues={{
              templateImage: "",
              templatePdf: "",
              productReference: "331182-dw-8oz-rect",
              productTitle: "",
              referenceInternal: "",
              brand: data?.brands[0],
              printColorModel: "4c CMYK",
              printTechnology: "Offset",
              surfaceType: "matt",
              version: "001",
              status: "offen"
            }}
            validationSchema={validationSchema}
            onSubmit={(formdata, { setSubmitting }) =>
              createProof({
                variables: { createProofInput: formdata },
              })
            }
          >
            {(form) => (
              <Form>


                <div className="py-2">
                  <h2>Auftragsdaten</h2>
                  <Row>
                    <Col lg>
                      <FormGroup className="mb-3" controlId="referenceInternal">
                        <FormLabel>Referenz*</FormLabel>
                        <FormControl
                          type="text"
                          name="referenceInternal"
                          required
                          value={form.values.referenceInternal}
                          onChange={form.handleChange} />
                      </FormGroup>
                    </Col>
                    <Col lg>
                      <FormGroup className="mb-3" controlId="brand">
                        <FormLabel>Marke</FormLabel>
                        <FormSelect
                          name="brand"
                          value={form.values.brand?.id}
                          onChange={(option) => form.setFieldValue('brand', data?.brands.find(brand => brand.id === parseInt(option.target.value)))}
                        >
                          {data?.brands.map((brand, key) => (
                            <option key={key} value={brand.id}>{brand.name}</option>
                          ))}
                        </FormSelect>
                      </FormGroup>
                    </Col>
                    <Col lg>
                      <FormGroup className="mb-3" controlId="status">
                        <FormLabel>Status*</FormLabel>
                        <FormSelect
                          name="status"
                          required
                          value={form.values.status || "offen"}
                          onChange={form.handleChange}
                        >
                          <option value="offen">offen</option>
                          <option value="angenommen">angenommen</option>
                          <option value="abgelehnt">abgelehnt</option>
                          <option value="storniert">storniert</option>
                        </FormSelect>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg>
                      <FormGroup className="mb-3" controlId="productReference">
                        <FormLabel>Produkt Referenz*</FormLabel>
                        <FormSelect
                          name="productReference"
                          value={form.values.productReference || "331182-dw-8oz-rect"}
                          onChange={form.handleChange}
                        >
                          <option value="none">keine</option>
                          <option disabled>-----EWB-----</option>
                          <option value="331182-dw-8oz-rect">331182-dw-8oz-rect</option>
                          <option value="331182-dw-8oz-warp">331182-dw-8oz-warp</option>
                          <option value="331182-dw-12oz-rect">331182-dw-12oz-rect</option>
                          <option value="331182-dw-12oz-warp">331182-dw-12oz-warp</option>
                          <option value="331182-dw-16oz-rect">331182-dw-16oz-rect</option>
                          <option value="331182-dw-16oz-warp">331182-dw-16oz-warp</option>
                          <option value="331182-sw-4oz-rect">331182-sw-4oz-rect</option>
                          <option value="331182-sw-4oz-warp">331182-sw-4oz-warp</option>
                          <option value="331182-sw-8oz-rect">331182-sw-8oz-rect</option>
                          <option value="331182-sw-8oz-warp">331182-sw-8oz-warp</option>
                          <option value="331182-sw-12oz-rect">331182-sw-12oz-rect</option>
                          <option value="331182-sw-12oz-warp">331182-sw-12oz-warp</option>
                          <option value="331182-sw-16oz-rect">331182-sw-16oz-rect</option>
                          <option value="331182-sw-16oz-warp">331182-sw-16oz-warp</option>
                          <option value="331182-sw-ice-4oz-rect">331182-sw-ice-4oz-rect</option>
                          <option value="331182-sw-ice-4oz-warp">331182-sw-ice-4oz-warp</option>
                          <option value="331182-sw-ice-6oz-rect">331182-sw-ice-6oz-rect</option>
                          <option value="331182-sw-ice-6oz-warp">331182-sw-ice-6oz-warp</option>
                          <option value="331182-sw-ice-8oz-rect">331182-sw-ice-8oz-rect</option>
                          <option value="331182-sw-ice-8oz-warp">331182-sw-ice-8oz-warp</option>
                          <option value="331215-dw-225ml-rect">331215-dw-225ml-rect</option>
                          <option value="331215-sw-100ml-rect">331215-sw-100ml-rect</option>
                          <option value="331215-sw-150ml-rect">331215-sw-150ml-rect</option>
                          <option value="331215-sw-180ml-rect">331215-sw-180ml-rect</option>
                          <option value="331215-sw-225ml-rect">331215-sw-225ml-rect</option>
                          <option value="331215-sw-300ml-rect">331215-sw-300ml-rect</option>
                          <option disabled>-----MWB-----</option>
                          <option value="331179-pru-200ml-rect">331179-pru-200ml-rect</option>
                          <option value="331213-pru-250ml-rect">331213-pru-250ml-rect</option>
                          <option value="331213-pru-300ml-rect">331213-pru-300ml-rect</option>
                          <option value="331213-pru-400ml-rect">331213-pru-400ml-rect</option>
                          <option value="331213-pru-500ml-rect">331213-pru-500ml-rect</option>
                          <option value="331213-pru-500ml-iml-elm">331213-pru-500ml-iml-elm</option>
                          <option value="331243-pru-250ml-rect">331243-pru-250ml-rect</option>
                          <option value="331243-pru-300ml-rect">331243-pru-300ml-rect</option>
                          <option value="331243-pru-400ml-rect">331243-pru-400ml-rect</option>
                          <option value="331243-pru-500ml-rect">331243-pru-500ml-rect</option>
                          <option disabled>-----test-----</option>
                          <option value="999999-sw-8oz-rect">999999-sw-8oz-rect</option>
                        </FormSelect>
                      </FormGroup>
                    </Col>
                    <Col lg>
                      <FormGroup className="mb-3" controlId="productTitle">
                        <FormLabel>Produkt Name*</FormLabel>
                        <FormControl
                          type="text"
                          name="productTitle"
                          required
                          defaultValue={form.values.productTitle}
                          onChange={form.handleChange} />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>

                <div className="py-2">
                  <h2>Druckdaten</h2>
                  <Row>
                    <Col lg>
                      <FormGroup className="mb-3" controlId="printTechnology">
                        <FormLabel>Produktionsart</FormLabel>
                        <FormControl
                          type="text"
                          name="printTechnology"
                          defaultValue={form.values.printTechnology || ""}
                          onChange={form.handleChange} />
                      </FormGroup>
                    </Col>
                    <Col lg>
                      <FormGroup className="mb-3" controlId="printColorModel">
                        <FormLabel>Farbmodus</FormLabel>
                        <FormControl
                          type="text"
                          name="printColorModel"
                          defaultValue={form.values.printColorModel || ""}
                          onChange={form.handleChange} />
                      </FormGroup>
                    </Col>
                    <Col lg>
                      <FormGroup className="mb-3" controlId="surfaceType">
                        <FormLabel>Oberfläche Typ*</FormLabel>
                        <FormSelect
                          name="surfaceType"
                          value={form.values.surfaceType || "matt"}
                          required
                          onChange={form.handleChange}
                        >
                          <option value="matt">matt</option>
                          <option value="glossy">glossy</option>
                        </FormSelect>
                      </FormGroup>
                    </Col>
                    <Col lg>
                      <FormGroup className="mb-3" controlId="version">
                        <FormLabel>Version</FormLabel>
                        <FormControl
                          type="text"
                          name="version"
                          defaultValue={form.values.version || ""}
                          onChange={form.handleChange} />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg>
                      <FormGroup className="mb-3" controlId="templatePdf">
                        <FormLabel>Druckdatei PDF</FormLabel>
                        <FormControl
                          type="text"
                          name="templatePdf"
                          defaultValue={form.values.templatePdf || ""}
                          onChange={form.handleChange} />
                      </FormGroup>
                    </Col>
                    <Col lg>
                      <FormGroup className="mb-3" controlId="templateImage">
                        <FormLabel>Druckdatei Bild</FormLabel>
                        <FormControl
                          type="text"
                          name="templateImage"
                          defaultValue={form.values.templateImage || ""}
                          onChange={form.handleChange} />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>

                <Button variant="success" type="submit">
                  <FontAwesomeIcon icon={faFloppyDisk} /> Speichern
                </Button>
              </Form>
            )}
          </Formik>

        </Modal.Body>
      </Modal>
    </>
  );
}